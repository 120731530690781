import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import PlutoOffers from '../plutoComp/PlutoOffers';
import PlutoOfferWalls from '../plutoComp/PlutoOfferWalls';
import PlutoChargebacks from '../plutoComp/PlutoChargebacks';
import PlutoFingerDet from '../plutoComp/PlutoFingerDet';
import PlutoIPDet from '../plutoComp/PlutoIPDet';
import PlutoPendingOffers from '../plutoComp/PlutoPendingOffers';
import PlutoSupportList from '../plutoComp/PlutoSupportList';
import PlutoSupportUser from '../plutoComp/PlutoSupportUser';
import PlutoSuspectOffers from '../plutoComp/PlutoSuspectOffers';
import PlutoUser from '../plutoComp/PlutoUser';
import PlutoUsers from '../plutoComp/PlutoUsers';
import PlutoWuthdrawal from '../plutoComp/PlutoWuthdrawal';
import PlutoSingleWithdrawal from '../plutoComp/PlutoSingleWithdrawal';
import PlutoHome from '../plutoComp/PlutoHome';
import DocumentMeta from 'react-document-meta';
import PlutoOfferDataTotal from '../plutoComp/PlutoOfferDataTotal';
import PlutoOfferDataTotalToday from '../plutoComp/PlutoOfferDataTotalToday';
import VerifyAdmin from "./PlutoVerify";
import { socket } from "../Containers/MainContainer";
import PlutoMenu from "./PlutoMenu";
import PlutoUsersToday from "./PlutoUsersToday";
import PlutoOffersToday from "./PlutoOffersToday";
import PlutoPendingPTC from "./PlutoPendingPTC";
import PlutoWithdrawalsToday from "./PlutoWithdrawalsToday";
import PlutoPaidCashOffers from "./PlutoPaidCashOffers";
import PlutoInvoices from "./PlutoInvoices";
import PlutoSameEmail from "./PlutoSameEmail";
import PlutoOffersFastComp from "./PlutoOffersFastComp";
export const AdminHome = (props) => {
    const [checkedIPInList, setCheckIPInList] = useState(false)
    const { referral_id, page, second_page, third_page } = useParams();
    const [isIPInTrusted, setISIPInTrusted] = useState(false)
    useEffect(() => {
        socket.emit('adminAction', {
            action: 'check_ip_trusted_admin'
        })
        socket.on('emitVerificationCodeAdmin', () => {
            socket.emit('adminAction', {
                action: 'check_ip_trusted_admin'
            })
        })
        socket.on('adminIPInTrustedList', (data) => {
            setCheckIPInList(true)
            setISIPInTrusted(data.status)
            window.dispatchEvent(new Event("end_loading"));

        })
    }, [])

    return (props.rank > 1 && props.logged == true ?
        <div>
            {checkedIPInList == false ? <span className="loader"></span> :
                isIPInTrusted == false ? <VerifyAdmin /> :
                    <div style={{background:'var(--bg-color-1)'}}>
                        <PlutoMenu rank={props.rank} />

                        {
                            second_page == 'offers' ?
                                <PlutoOffers />
                                : second_page == 'chargebacks' ?
                                    <PlutoChargebacks />
                                    : second_page == 'offer-total' ? <PlutoOfferDataTotal second_page={third_page} />
                                        : second_page == 'offer-today' ? <PlutoOfferDataTotalToday second_page={third_page} />
                                            : second_page == 'fingerprint' ?
                                                third_page != undefined ? <PlutoFingerDet second_page={third_page} /> : ''
                                                  : second_page == 'emails' ?
                                                third_page != undefined ? <PlutoSameEmail second_page={third_page} /> : ''
                                                : second_page == 'ip' ?
                                                    third_page != undefined ? <PlutoIPDet second_page={third_page} /> : ''
                                                    : second_page == 'offerwalls' ?
                                                        <PlutoOfferWalls />
                                                        : second_page == 'pc-offers' ?
                                                            <PlutoPaidCashOffers />
                                                            : second_page == 'ptcs' ?
                                                                <PlutoPendingPTC />
                                                                : second_page == 'pending-offers' ?
                                                                    third_page != undefined ? <PlutoPendingOffers x={1} second_page={third_page} /> : <PlutoPendingOffers x={0} /> :
                                                                    second_page == 'support-chat' ?
                                                                        third_page != undefined ? <PlutoSupportUser second_page={third_page} /> : <PlutoSupportList /> :
                                                                        second_page == 'suspect-offers' ?
                                                                            <PlutoSuspectOffers />
                                                                            : second_page == 'user' ?
                                                                                third_page != undefined ? <PlutoUser second_page={third_page} /> : <PlutoUsers />
                                                                                : second_page == 'users-today' ?
                                                                                    <PlutoUsersToday />
                                                                                    : second_page == 'withdrawals-today' ?
                                                                                        <PlutoWithdrawalsToday />
                                                                                        : second_page == 'offers-today' ?
                                                                                            <PlutoOffersToday />
                                                                                            : second_page == 'offer-fastc' ?
                                                                                            <PlutoOffersFastComp /> :
                                                                                            second_page == 'invoices' ?
                                                                                            <PlutoInvoices /> :
                                                                                            second_page == 'withdrawals' ?
                                                                                                third_page != undefined ? <PlutoSingleWithdrawal second_page={third_page} /> : <PlutoWuthdrawal /> :
                                                                                                <PlutoHome />
                        }
                    </div>}
        </div> : '');
}