'use client'
import { socket } from '../Containers/MainContainer';
import { canUsePagination, numberWithCommas } from '../utils';

import { useEffect } from 'react';
import { useState } from 'react';
import { Pagination } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import PlutoMenu from './PlutoMenu';
import PaginationPluto from './Pagination';
import VerifyAdmin from './PlutoVerify';
import { Link } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

function PlutoOffersFastComp() {
    const [userProfile, setUserProfile] = useState({})
    const [earnings, setEarnings] = useState([0, 0, 0, 0, 0]);
    const [page, setPage] = useState(1)
    const [pageNumber, setPageNumber] = useState(-1)
    const [userId, setUserId] = useState(0);
    const [canUse, setCanUse] = useState(true);
    const [username, setUsername] = useState('')
    const [minutes, setMinutes] = useState(100)
    const [rank, setRank] = useState(0)
     const [condition, setCondition] = useState(0)
        const [conditionReason, setConditionReason] = useState('')
  const [alert, setAlert] = useState({ show: false, name: '', minutes: '', code: '', pin: '', id: -1, req_hold:0.4 })
    const [alert2, setAlert2] = useState({ show: false, name: '', minutes: '', code: '', pin: 0 })
    useEffect(() => {
        socket.on('response_get_admin_offers_fast_c', (data) => {
            setEarnings(data.withdrawals)
            setPageNumber(Math.ceil(data.withdrawalsLength / 5))
            setUserProfile(data)
            canUsePagination(true)
            setRank(data.rank)

        })
        socket.emit('getOffersAdminFastC', {
            offers_like: username,
            page,
        })
    }, [])
    useEffect(() => {
        socket.on('reTakeSpeedyData', () => {
            socket.emit('getOffersAdminFastC', {
                offers_like: username,
                page,
            })
        })
    }, [])
    return (
        <SkeletonTheme baseColor="#0d131c" highlightColor="#111923">
<SweetAlert
                show={alert2.show}
                onConfirm={function () {
                    socket.emit('adminAction', {
                        action: 'delete_speedy_offer',
                        id: alert.id
                    })
                    setAlert2({ show: false })
                    setAlert({ show: false, name: '', minutes: '', code: '', pin: '', id: -1 })
                }}
                style={{ backgroundColor: '#3a3a3a', color: '#f3f3f3', }}
                cancelBtnText="Not now"
                confirmBtnText="Delete"
                showCancel={true}
                cancelBtnStyle={{ color: '#c1c1c1', textDecoration: 'none' }}
                confirmBtnStyle={{ border: 'none', boxShadow: 'none', padding: '5px 15px', backgroundColor: '#242424' }}
                onCancel={function () { setAlert2({ show: false }) }}
            >
                <>
                    <h3>Are you sure that you want to delete {alert.name}#{alert.id}?</h3>
                </>

            </SweetAlert>
            <SweetAlert
                show={alert.show}
                title={alert.title}
                onConfirm={function () {
                    if (alert.name.split("").length > 2 && parseInt(alert.minutes) > 0 ) {
                        if (alert.type == "add") {
                            socket.emit('adminAction', {
                                action: 'add_speedy_offer',
                                name: alert.name,
                                minutes: alert.minutes,
                                condition_action: alert.minutes,
                            })
                            setAlert({ ...alert, show: false })
                        } else if (alert.type == "edit") {
                            socket.emit('adminAction', {
                                action: 'edit_speedy_offer',
                                id: alert.id,
                                name: alert.name,
                                condition_action: alert.minutes,
                            })
                            setAlert({ ...alert, show: false })
                        }
                    }
                }}
                style={{ backgroundColor: '#3a3a3a', color: '#f3f3f3', }}
                cancelBtnText="Not now"
                confirmBtnText="Proceed"
                showCancel={true}
                cancelBtnStyle={{ color: '#c1c1c1', textDecoration: 'none' }}
                confirmBtnStyle={{ border: 'none', boxShadow: 'none', padding: '5px 15px', backgroundColor: '#242424' }}
                onCancel={function () { setAlert({ show: false, type: '', flagReason: '', code: '', pin: '', id: -1 }) }}
            >
                <>
                    <h3>Add New Speedy Offer:</h3>
                    <input class="form-control" style={{ marginBottom: '10px', color: '#fff', background: 'transparent' }} defaultValue={alert.name || ""} placeholder={alert.name || "Offer Name"} type="text" onKeyUp={(e) => {
                        setAlert({ ...alert, name: e.target.value.toLowerCase() })
                    }}></input>
                    <p>Name: {alert.name}</p>
                 
                    
                    <p>Minutes: {alert.minutes}</p>
                    <input class="form-control" style={{ marginBottom: '10px', color: '#fff', background: 'transparent' }} defaultValue={alert.minutes} type="number" onChange={(e)=>{
                        setAlert({ ...alert, minutes: e.target.value.toLowerCase() })

                    }} onKeyUp={(e) => {
                        setAlert({ ...alert, minutes: e.target.value.toLowerCase() })
                    }}></input>

                    {alert.type == "edit" ? <div onClick={() => {
                        setAlert({ ...alert, show: false });
                        setAlert2({ ...alert, show: true });

                    }} style={{ margin: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><div style={{ width: '200px', cursor: 'pointer', background: 'red' }}>Delete</div></div> : ''}
                </>

            </SweetAlert>
            <div class="modal-body" style={{ color: 'var(--text-primary)' }}>
                <div className="user-details-stats">

                    {userProfile.withdrawalsLength != undefined ?
                        <div className="card">
                            <p>
                                {numberWithCommas(userProfile.withdrawalsLength)}
                            </p>
                            <p>Count offers</p>
                        </div>
                        : <Skeleton style={{ width: '136px', height: '50px' }} />}


                </div>
                <div style={{ overflow: 'auto', margin: '0 auto', padding: '20px', marginBottom: '20px', background: 'var(--accent-1)' }}>
                    <p style={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>Speedy Offers</p>
                    <p style={{ color: 'var(--text-secondary)', fontWeight: 'bold', fontSize:'12px' }}>Please be aware that offers made under the mentioned minutes and name are to be banned with the following reason: 'Speedy Offers [n-001]'</p>
                    <div onClick={() => {
                        setAlert({ ...alert, show: true, type: 'add', id: -1 })
                    }} className="btn button-disabled" style={{ minWidth: '100px', height: '15px', fontSize: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100px', maxWidth: '100px', marginBottom: '5px', }} >Add new</div>
                    <div style={{ fontWeight: 'bold' }}>
                        <div className="user-details-table-elements" >
                            <div style={{ width: '250px', textAlign: 'left', color: 'var(--text-primary)' }} >Name</div>
                            <div style={{ width: '200px' }}>Minutes</div>
                            <div style={{ width: '100px' }}>Edit</div>
                        </div>
                    </div>

                    {earnings.map((earning, i) => {
                        return (
                            <div key={earning.id} className="user-details-table-elements admin-a" style={{ color: 'var(--text-primary)' }}>

                                <div style={{ width: '250px', textAlign: 'left', color: 'var(--text-primary)' }} >{earning.name || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ width: '200px' }} >{earning.minutes || <Skeleton style={{ width: '30px' }} />}</div>
                              

                             <div onClick={() => {
                                                                 setAlert({ ...alert,name:earning.name, minutes:earning.minutes, show: true, type: 'edit', id: earning.id })
                                                             }} className="btn button-disabled" style={{ width: '100px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100px', maxWidth: '100px' }} >{earning.id == undefined ? <Skeleton style={{ width: '30px' }} /> : 'Edit'}</div>

                            </div>
                        )
                    })}
                </div>
                {pageNumber == -1 ? <Skeleton style={{ width: '100%' }} /> : <PaginationPluto pageNumber={pageNumber} requestNewList={(page) => {
                    canUsePagination(false)
                    setEarnings([0, 0, 0, 0, 0])
                    socket.emit('getOffersAdminFastC', {
                        offers_like: username,
                        minutes,
                        page,
                    })
                }} />}

            </div>
            <div >
                <input onKeyDown={
                    (e) => {
                        if (e.key == 'Enter') {
                            canUsePagination(false)
                            setEarnings([0, 0, 0, 0, 0])
                            socket.emit('getOffersAdminFastC', {
                                offers_like: username,
                               minutes, page: document.getElementById('jumptotoday2fastc').value,
                            })
                        }
                    }

                } type="number" id="jumptotoday2fastc" style={{ background: 'gray', color: '#000' }} />
                <button onClick={() => {
                    canUsePagination(false)
                    setEarnings([0, 0, 0, 0, 0])
                    socket.emit('getOffersAdminFastC', {
                        offers_like: username,
                       minutes, page: document.getElementById('jumptotoday2fastc').value,
                    })
                }
                } style={{ color: '#fff', background: '#000' }}>Go to page</button>
            </div>
        </SkeletonTheme>
    )
}

export default PlutoOffersFastComp