
import { FaExclamationCircle, FaRocket } from "react-icons/fa";
import { toast } from "react-toastify";
import { socket } from "./Containers/MainContainer";
import { load } from '@fingerprintjs/botd'
import jwt_decode from "jwt-decode";
import axios from "axios";

export function getLocalStorage(key) {
    if (typeof localStorage == 'undefined') {
        return setTimeout(() => {
            return getLocalStorage(key)
        }, 1000);
    } else {
        const item = localStorage.getItem(key);
        return item;
    }
}

export function setLocalStorage(key, value) {
    localStorage.setItem(key, value);
    window.dispatchEvent(new Event(key));
}
export function removeLocalStorage(key) {
    localStorage.removeItem(key);
}
export function getIsBanned() {
    let chatStatus = getLocalStorage('banned')
    if (chatStatus == null || chatStatus == undefined) {
        setLocalStorage('banned', false)
        return false;
    }
    return chatStatus == 'true' ? true : false;
}
export function getChatStatus() {
    let chatStatus = getLocalStorage('showChat')
    if (chatStatus == null || chatStatus == undefined) {
        setLocalStorage('showChat', true)
        return true;
    }
    return chatStatus == 'true' ? true : false;
}
export function getDevicesSelected() {
    let chatStatus = getLocalStorage('selectedDevices')
    if (chatStatus == null || chatStatus == undefined) {
        setLocalStorage('selectedDevices', JSON.stringify(['windows', 'ios', 'android']))
        return ['windows', 'ios', 'android'];
    }
    return chatStatus == undefined ? ['windows', 'ios', 'android'] : JSON.parse(chatStatus).filter(x => x == "windows" || x == "ios" || x == "android");
}
export function setDevicesSelected(devices) {
    let chatStatus = devices
    setLocalStorage('selectedDevices', JSON.stringify(chatStatus.filter(x => x == "windows" || x == "ios" || x == "android")))
    return getDevicesSelected();
}
export function getWallName() {
    let chatStatus = getLocalStorage('wallname')
    if (chatStatus == null || chatStatus == undefined) {
        return undefined;
    }
    return chatStatus;
}
export function getPhoneNumber() {
    let chatStatus = getLocalStorage('phoneNumber')
    if (chatStatus == null || chatStatus == undefined) {
        return undefined;
    }
    return chatStatus;
}
export function getSignUserTab() {
    let tabSelected = getLocalStorage('signUserTab')
    if (tabSelected == null || tabSelected == undefined) {
        setLocalStorage('signUserTab', 0)
        return 0;
    }
    return tabSelected;
}
export function getShownCurency() {
    let currency = getLocalStorage('shownCurrency')
    if (currency == null || currency == undefined) {
        setLocalStorage('shownCurrency', 'coin')
        return 'coin';
    }
    return currency;
}
export function getOfferWindowStatus() {
    let offerWindow = getLocalStorage('offerWindowStatus')
    if (offerWindow == null || offerWindow == undefined) {
        setLocalStorage('offerWindowStatus', false)
        return false;
    }
    return offerWindow == 'true' ? true : false;
}
export function getChatTab() {
    let chatTab = getLocalStorage('chatTab')
    if (chatTab == null || chatTab == undefined) {
        setLocalStorage('chatTab', 0)
        return 0;
    }
    return chatTab;
}
export function setChatTab(i) {
    let chatTab = setLocalStorage('chatTab', i)
}

export function getDesiredGiftCard() {
    let desiredGiftCard = getLocalStorage('showGiftCard')
    if (desiredGiftCard == null || desiredGiftCard == undefined) {
        return withdrawGiftcardCards[0];
    }
    desiredGiftCard = desiredGiftCard.split(",")
    let withdrawElement = desiredGiftCard[1] == "giftcards" ? withdrawGiftcardCards[desiredGiftCard[0]] : withdrawSkinsCards[desiredGiftCard[0]]
    return withdrawElement;
}

export function getDesiredCryptoCard() {
    let desiredCryptoCard = getLocalStorage('showCryptoCard')
    if (desiredCryptoCard == null || desiredCryptoCard == undefined) {
        return withdrawCryptoCards[0];
    }
    return withdrawCryptoCards[desiredCryptoCard];
}

export function setChatStatus(value) {
    setLocalStorage('showChat', value)
}
export function setShownCurrency(value) {
    setLocalStorage('shownCurrency', value)
}
export function setSignUserTab(value) {
    setLocalStorage('signUserTab', value)
}
export function setOfferWindow(value) {
    setLocalStorage('offerWindowStatus', value)
}
export function setChatTabOpen(value) {
    setLocalStorage('chatTab', value)
}
export function setDesiredGiftCard(value) {
    setLocalStorage('showGiftCard', value)
}
export function setDesiredCrypto(value) {
    setLocalStorage('showCryptoCard', value)
}

export function ChangeStateUrl(url) {
    const { pathname: path } = new URL(window.location.origin + window.location.pathname + url);
    window.history.pushState({ path }, path, window.location.origin + window.location.pathname + url);
    window.dispatchEvent(new Event('pageChange'));
}
export function ChangeStateUrlPath(url) {
    const { pathname: path } = new URL(window.location.origin + url);
    window.history.pushState({ path }, path, window.location.origin + url);
    window.dispatchEvent(new Event('pageChange'));
}
function formatDate(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm }
    date = mm + '/' + dd + '/' + yyyy;
    return date
}
function formatDateDM(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm }
    date = dd + '/' + mm;
    return date
}

function formatDateM(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm }
    date = mm + '/' + yyyy;
    return date
}

export function getNextMonthLastDay() {
    const today = new Date();
    
    // Move to the first day of the next month
    const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    
    // Set the date to the 0th day of the following month (i.e., last day of the next month)
    const lastDay = new Date(nextMonth.getFullYear(), nextMonth.getMonth() + 1, 0);
    
    return lastDay.getDate();
}

export function Last7Days() {
    var result = [];
    for (var i = 0; i < 7; i++) {
        var d = new Date();
        d.setDate(d.getDate() - i);
        result.push(formatDateDM(d))
    }

    return (result.join(','));
}
export function Last7Months() {
    var result = [];
    for (var i = 0; i < 7; i++) {
        var d = new Date();
        d.setMonth(d.getMonth() - i);
        result.push(formatDateM(d))
    }
    return (result.join(','));
}
export function Last24Months() {
    var result = [];
    for (var i = 0; i < 24; i++) {
        var d = new Date();
        d.setMonth(d.getMonth() - i);
        result.push(formatDateM(d))
    }
    return (result.join(','));
}
export function numberWithCommas(xn) {
    let x = (xn + '').toString()

    if (x.toString().indexOf(".") != -1) {
        let afterPoint = x.split(".");
        return afterPoint[0].toString().replace(/\B(?=(\d{3})+\b)/g, ",") + "." + afterPoint[1];
    } else {
        return x.toString().replace(/\B(?=(\d{3})+\b)/g, ",");

    }
}
export function USDWithCommas(xn) {
    let x = parseFloat((parseInt(xn) / 1000)).toFixed(2).toString()
    if (x.toString().indexOf(".") != -1) {
        let afterPoint = x.split(".");
        return afterPoint[0].toString().replace(/\B(?=(\d{3})+\b)/g, ",") + "." + afterPoint[1];
    } else {
        return x.toString().replace(/\B(?=(\d{3})+\b)/g, ",");

    }
}
export function playAlert() {
    let sound = document.querySelector('#alert-sound');
    if (sound.paused == false) {
        sound.pause()
    }
    try{
    sound.play()
    }catch(e){}
}

export function getLevelColor(level) {
    if (level <= 10) {
        return '#2c56c9';
    } else if (level >= 1000) {
        return '-webkit-gradient(linear,left top,right bottom,color-stop(0,hsla(0,80%,70%,0.8)),color-stop(0.15,hsla(280deg,80%,70%,0.8)),color-stop(0.3,hsla(240deg,80%,70%,0.8)),color-stop(0.45,hsla(180deg,80%,70%,0.8)),color-stop(0.6,hsla(140deg,80%,70%,0.8)),color-stop(0.75,hsla(100deg,80%,70%,0.8)),color-stop(0.9,hsla(60deg,80%,70%,0.8)),color-stop(1,hsla(0,80%,70%,0.8)))'
    } else if (level >= 500) {
        return '#efcb4899';
    } else if (level >= 250) {
        return '#6ab65b99';
    } else if (level >= 100) {
        return '#852626';
    } else if (level >= 50) {
        return '#167130';
    } else if (level > 10) {
        return '#782cc9';
    }
}
export function CreateToast(message, useRef, type = 0) {
    const toastId = useRef;
    toastId.current = toast(message);
    let status = 'success'
    if (type == 1) {
        status = 'error'
    }
    //
    playAlert()
    toast.update(toastId.current, {
        icon: type == 0 ? <FaRocket /> : <FaExclamationCircle />,
        className: `toastify-${status}`

    })
}
export function showCurrencyText(amount) {
    return getShownCurency() == 'coin' ? numberWithCommas(parseFloat(amount).toFixed(0)) : numberWithCommas(parseFloat(amount / 1000).toFixed(3))
}
export function emitSocket(id, data) {
    if (window.socket != undefined) {
        window.socket.emit(id, data);
    }
}
export async function onSocket(id, cb) {
    if (window.socket != undefined) {
        window.socket.off(id).on(id, (data) => {
            cb(data)
            // window.dispatchEvent(new CustomEvent(id,{"data":data}), );
        });


    }
}
export function canUsePagination(canUse) {
    window.dispatchEvent(new CustomEvent('canUsePagination', { detail: canUse }),);
}
export function getWithdrawFeedImage(type) {
    let options = {
        'litecoin': 'crypto/litecoin.png',
        'dogecoin': 'crypto/dogecoin.png',
        'bitcoin cash': 'crypto/bitcoincash.png',
        'tron': 'crypto/tron.png',
        'ethereum': 'crypto/ethereum.png',
        'bitcoin': 'crypto/bitcoin.png',
        'apple': 'giftcards/apple.png',
        'amazon': 'giftcards/amazon.png',
        'visa': 'giftcards/visa.png',
        'paypal': 'giftcards/paypal_small.png',
        'apple': 'giftcards/apple.png',
        'netflix': 'giftcards/netflix.png',
        'google play': 'giftcards/googleplay.png',
        'spotify': 'giftcards/spofity.png',
        'steam': 'giftcards/steam.png',
        'xbox': 'giftcards/xbox.png',
        'nintendo': 'giftcards/nintendo.png',
        'airbnb': 'giftcards/airbnb.png',
        'fortnite': 'giftcards/fortnite.png',
        'valorant': 'giftcards/valorant.png',
        'roblox': 'giftcards/roblox.png',
        'league of legends': 'giftcards/lol.png',

    }
    return options[type]
}

export async function getFingerprints(fingerprint) {
    let botData;
    try {
        const botdPromise = await load()
        // Get detection results when you need them.
        let detect = botdPromise.detect();
        botData = detect
    } catch (e) {
        botData = { bot: undefined }
    }
    try {
        const fingerPrintsLoad = await fingerprint.load();
        const fingerPrintsObj = await fingerPrintsLoad.get()
        const fingerPrints = {
            audio: fingerPrintsObj.components.audio.value,
            browser: `${fingerPrintsObj.components.vendor.value} ${fingerPrintsObj.components.vendorFlavors.value.join(" ")}`,
            cpuCores: fingerPrintsObj.components.hardwareConcurrency.value,
            deviceMemory: fingerPrintsObj.components.deviceMemory.value,
            language: fingerPrintsObj.components.languages.value[0].join(" "),
            platform: fingerPrintsObj.components.platform.value,
            resolution: `${fingerPrintsObj.components.screenResolution.value[0]}x${fingerPrintsObj.components.screenResolution.value[1]}`,
            timezone: fingerPrintsObj.components.timezone.value,
            useragent: navigator.userAgent,
            visitorId: fingerPrintsObj.visitorId,
            bot: botData,
        }

        return fingerPrints;
    } catch (e) {
    }
}
export function getGiftCardData(title) {
    let skinsData = withdrawSkinsCards.map(x => x.name);
    let gcData = withdrawGiftcardCards.map(x => x.name);
    let found = -1;
    for (let i = 0; i < gcData.length; i++) {
        if (gcData[i].toLowerCase() == title) {
            found = i;
        }
    }
    if (found != -1) {
        return withdrawGiftcardCards[found];
    }
    for (let i = 0; i < skinsData.length; i++) {
        if (skinsData[i].toLowerCase() == title) {
            found = i;
        }
    }
    if (found != -1) {
        return withdrawSkinsCards[found];
    }
    return {
        type: 'Skins',//
        name: 'Gift Card',
        height: '100px',
        width: '110px',
        background: '#027efc'
    }
}
export async function submitFingers(fingerprint) {
    socket.emit('submitFingers', await getFingerprints(fingerprint),)
}

export async function submitFingersDRO(fingerprint, session) {
    socket.emit('submitFingersDRO', {session:session, fp: await getFingerprints(fingerprint)})
}
export async function submitFingersWall(fingerprint) {
    socket.emit('submitFingersWall', await getFingerprints(fingerprint),)
}
export function validateUsername(username) {
    if (/\s/g.test(username)) {
        return [false, 'Username must not contain space characters.']
    } else if (/[\.\<\>\:\;\'\"\|\~\`]/g.test(username)) {
        return [false, 'Username must not contain special characters.']
    } else if (username.trim().split("").length < 6) {
        return [false, 'Username must be at least 6 characters long.']
    } else if (username.trim().split("").length > 30) {
        return [false, 'Username can contain only 30 characters.']
    } else {
        return [true, '']
    }
}

export function validateEmail(mail) {
    if (!mail.includes("@")) {
        return false
    }
    if (!mail.split("@")[1].includes(".")) {
        return false
    }
    if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(mail)) {
        return (true)
    }
    return (false)
}
export function validatePassword(p) {
    if (p.length < 6) {
        return [false, 'Password must be at least 6 characters long.']
    }
    if (!/\d/.test(p)) {
        return [false, 'Password must contain at least one digit.']
    }
    if (!/[a-z]/.test(p)) {
        return [false, 'Password must contain at least one lower case character.']
    }
    if (!/[A-Z]/.test(p)) {

        return [false, 'Password must contain at least one upper case character.']
    }
    if (!/[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g.test(p)) {
        return [false, 'Password must contain at least one special character.']
    }
    return [true, '']
}
export function getUsername() {
    let jwt = jwt_decode(getCookie('session_token'));
    return jwt.username;
}
export function getUserID() {
    let jwt = jwt_decode(getCookie('session_token'));
    return jwt.userId;
}export function getUserIDFromSesion(session) {
    let jwt = jwt_decode(session);
    return jwt.userId;
}
export function getUserPCID() {
    let jwt = jwt_decode(getCookie('session_token'));
    return jwt.pcid == undefined ? 'uid_' + getUserID() : jwt.pcid;
}
export function NotAvailableCountry(country) {
    let countries = []
    // console.log(countries.includes(country), country)
    return countries.includes(country) ? true : false;
}
export function getOfferWallName(name) {
    switch (name) {
        case 'cpx-research':
            return 'CPX Research';
            break;
        case 'timewall':
            return 'TimeWall';
            break;
        case 'notik':
            return 'Notik';
            break;
        case 'bitlabs':
            return 'Bitlabs';
            break;
        case 'pollfish':
            return 'Pollfish';
            break;
        case 'adscendmedia':
            return 'AdscendMedia';
            break;
            case 'adscendmedia-surveys':
            return 'AdscendMedia Surveys';
            break;
        case 'mychips':
            return 'My Chips';
            break;
        case 'precision-sample':
            return 'Precision Sample';
        case 'ayet-studios':
            return 'AyeT Studios';
            break;
        case 'offertoro':
            return 'ToroX';
            break;
        case 'earnwave':
            return 'EarnWave';
            break;
        case 'admantium':
            return 'Admantium';
            break;
        case 'bitlabs-offers':
            return 'BitLabs Offers';
            break;
        case 'adtowall':
            return 'AdToWall';
            break;
        case 'mylead':
            return 'MyLead';
            break;
        case 'revlum':
            return 'Revlum';
            break;
        case 'lootably':
            return 'Lootably';
            break;
        case 'monlix':
            return 'Monlix';
            break;
        case 'adgatemedia':
            return 'AdGateMedia';
            break;
        case 'theoremreach':
            return 'TheoremReach';
            break;
        case 'adgem':
            return 'AdGem';
            break;
        case 'revenue-universe':
            return 'Revenue Universe';
            break;
        case 'revenue universe':
            return 'Revenue Universe';
            break;
        case 'mmwall':
            return 'Make Money';
            break;
        case 'paidcash-exclusive':
            return 'PaidCash Exclusive';
            break;
            case 'adjoe':
                return 'PlayTime';
                break;
        case 'sumpix':
            return 'SumpixMedia';
            break;
        case 'hang my ads':
            return 'Hang My Ads';
            break;
        case 'hangmyads':
            return 'Hang My Ads';
            break;
        case 'tyrads':
            return 'TyrAds';
            break;
        case 'farly':
            return 'Farly';
            break;
        case 'hma':
            return 'Hang My Ads';
            break;
        case 'appprize':
            return 'AppPrize';
            break;
        case 'paidcash':
            return 'PaidCash';
            break;
    }
}
export const withdrawCryptoCards = [
    // {
    //     type: 'Crypto',
    //     img: '/assets/img/crypto/bitcoin1.png',
    //     name: 'Bitcoin',
    //     unit: 'btc',
    //     height: '45px',
    //     width: '45px',
    //     background: 'linear-gradient(336.89deg,#131739 -26.71%,#dfa40a 107.56%)'
    // }, 
    {
        type: 'Crypto',
        img: '/assets/img/crypto/litecoin1.png',
        name: 'Litecoin',
        unit: 'ltc',
        height: '30px',
        width: '30px',
        background: 'linear-gradient(336.89deg,#131739 -26.71%,#8c8caa 107.56%)'
    }, {
        type: 'Crypto',
        img: '/assets/img/crypto/dogecoin1.png',
        name: 'Dogecoin',
        unit: 'doge',
        height: '35px',
        width: '35px',
        background: 'linear-gradient(336.89deg,#131739 -26.71%,#cd9949 107.56%)'
    }, 
    // {
    //     type: 'Crypto',
    //     img: '/assets/img/crypto/ethereum2.png',
    //     name: 'Ethereum',
    //     unit: 'eth',
    //     height: '45px',
    //     width: '45px',
    //     background: 'linear-gradient(336.89deg,#131739 -26.71%,#4b728f 107.56%)'
    // }, 
    {
        type: 'Crypto',
        img: '/assets/img/crypto/bitcoin1.png',
        name: 'Bitcoin Cash',
        unit: 'bch',
        height: '45px',
        width: '45px',
        background: 'linear-gradient(336.89deg,#131739 -26.71%,#5b8f4b 107.56%)'
    }, {
        type: 'Crypto',
        img: '/assets/img/crypto/tron1.png',
        name: 'Tron',
        unit: 'trx',
        height: '35px',
        width: '35px',
        background: 'linear-gradient(336.89deg,#131739 -26.71%,#bc4646 107.56%)'
    },
]
export const withdrawGiftcardCards = [
    {
        type: 'GiftCard',//
        img: '/assets/img/giftcards/paypal.png',
        name: 'paypal',
        height: '40px',
        width: '80px',
        background: 'linear-gradient(125deg, #003087, #009cde)'
    },
    {
        type:'GiftCard',//
        img:'/assets/img/giftcards/visa.png',
        name:'Visa',
        height:'40px',
        width:'80px',
        background:'linear-gradient(125deg, rgb(18 87 159), rgb(18 87 159))'
    },
    {
        type: 'GiftCard',//
        img: '/assets/img/giftcards/apple.png',
        name: 'apple',
        height: '70px',
        width: '40px',
        background: 'linear-gradient(130deg, rgb(255, 91, 83) 0%, rgb(152, 80, 250) 54.58%, rgb(44, 201, 248) 100%)'
    },
    // {
    //     type: 'GiftCard',//
    //     img: '/assets/img/giftcards/netflix.png',
    //     name: 'netflix',
    //     height: '100px',
    //     width: '110px',
    //     background: '#fff'
    // }, 
    {
        type: 'GiftCard',//
        img: '/assets/img/giftcards/amazon.png',
        name: 'amazon',
        height: '100px',
        width: '110px',
        background: 'linear-gradient(316deg, rgb(17, 23, 30) 0%, rgb(44, 49, 55) 100%)'
    }, 
    {
        type: 'GiftCard',//
        img: '/assets/img/giftcards/doordash.png',
        name: 'doordash',
        height: '100px',
        width: '120px',
        background: 'linear-gradient(125deg, #FF3008, #C70000)'
    },
    {
        type: 'GiftCard',//
        img: '/assets/img/giftcards/starbucks.png',
        name: 'starbucks',
        height: '100px',
        width: '120px',
        background: 'linear-gradient(125deg, #00704A, #1E3932)'
    },

    {
        type: 'GiftCard',//
        img: '/assets/img/giftcards/just-eat.png',
        name: 'just eat',
        height: '100px',
        width: '120px',
        background: 'linear-gradient(125deg, #EA5C10, #FF8714)'
    },
    {
        type: 'GiftCard',//
        img: '/assets/img/giftcards/ikea.png',
        name: 'ikea',
        height: '100px',
        width: '120px',
        background: 'linear-gradient(125deg, rgb(41 106 190), rgb(0, 81, 186))'
    },
    {
        type: 'GiftCard',//
        img: '/assets/img/giftcards/zalando.png',
        name: 'zalando',
        height: '100px',
        width: '120px',
        background: 'linear-gradient(125deg, #FF5E00, #E9650E)'
    },
    {
        type: 'GiftCard',//
        img: '/assets/img/giftcards/decathlon.png',
        name: 'decathlon',
        height: '100px',
        width: '120px',
        background: 'linear-gradient(125deg, #0066CC, #004099)'
    },
    {
        type: 'GiftCard',//
        img: '/assets/img/giftcards/kaufland.png',
        name: 'kaufland',
        height: '100px',
        width: '120px',
        background: 'linear-gradient(125deg, #E60018, #960015)'
    },
    {
        type: 'GiftCard',//
        img: '/assets/img/giftcards/auchan.png',
        name: 'auchan',
        height: '100px',
        width: '120px',
        background: 'linear-gradient(125deg, #E2001A, #A80012)'
    },
    {
        type: 'GiftCard',//
        img: '/assets/img/giftcards/walmart.png',
        name: 'walmart',
        height: '100px',
        width: '120px',
        background: 'linear-gradient(125deg, rgb(0, 113, 206), rgb(112 149 146))'
    },
    // {
    //     type: 'GiftCard',//
    //     img: '/assets/img/giftcards/spotify.png',
    //     name: 'spotify',
    //     height: '30px',
    //     width: '100px',
    //     background: 'linear-gradient(81.14deg,#7ab55c 25.96%,#38ffc3 147.65%)'
    // }, {
    //     type: 'GiftCard',//
    //     img: '/assets/img/giftcards/steam.png',
    //     name: 'steam',
    //     height: '100px',
    //     width: '120px',
    //     background: 'linear-gradient(336.89deg,#131739 -26.71%,#4b4d8f 107.56%)'
    // }, {
    //     type: 'GiftCard',//
    //     img: '/assets/img/giftcards/playstation.png',
    //     name: 'playstation',
    //     height: '40px',
    //     width: '50px',
    //     background: 'linear-gradient(336.89deg,#013279 -26.71%,#0b80b9 107.56%)'
    // }, {
    //     type: 'GiftCard',//
    //     img: '/assets/img/giftcards/xbox.png',
    //     name: 'xbox',
    //     height: '40px',
    //     width: '120px',
    //     background: 'linear-gradient(190.72deg,#57ff3c -85.82%,#177d3e 92.04%)'
    // }, {
    //     type: 'GiftCard',//
    //     img: '/assets/img/giftcards/nintendo.png',
    //     name: 'nintendo',
    //     height: '40px',
    //     width: '120px',
    //     background: '#ff000a'
    // }, 
    {
        type: 'GiftCard',//
        img: '/assets/img/giftcards/airbnb.png',
        name: 'airbnb',
        height: '35px',
        width: '100px',
        background: 'linear-gradient(125deg, #FF5A5F, #FF385C)'
    },
]
export const withdrawSkinsCards = [
    //     {
    //     type: 'Skins',//
    //     img: '/assets/img/giftcards/fortnite.png',
    //     name: 'fortnite',
    //     height: '100px',
    //     width: '110px',
    //     background: '#027efc'
    // }, {
    //     type: 'Skins',//
    //     img: '/assets/img/giftcards/valorant.png',
    //     name: 'valorant',
    //     height: '130px',
    //     width: '130px',
    //     background: '#f2555a'
    // }, {
    //     type: 'Skins',//
    //     img: '/assets/img/giftcards/roblox.png',
    //     name: 'roblox',
    //     height: '100px',
    //     width: '110px',
    //     background: '#e2231a'
    // }, {
    //     type: 'Skins',//
    //     img: '/assets/img/giftcards/lol.png',
    //     name: 'league of legends',
    //     height: '100px',
    //     width: '110px',
    //     background: '#54482f'
    // }
]

export function hideModal(className) {
    document.querySelector(className).parentElement.style.display = 'none';
}

export function showModal(className) {
    console.log(document.querySelector(className))
    document.querySelector(className).parentElement.style.display = 'flex';
}

export function hideLoginModal() {
    hideModal('.modal-content-login');
}
export function showLoginModal() {
    showModal('.modal-content-login');
}
export function hideFaucetModal() {
    hideModal('.faucet-modal');
}
export function showFaucetModal() {
    showModal('.faucet-modal');
}
export function hidePhoneNumberVerifications() {
    hideModal('.phone-number--verifications-modal');
}
export function showPhoneNumberVerifications() {
    showModal('.phone-number--verifications-modal');
}
export function hidePhoneNumberSendSms() {
    hideModal('.phone-number--send-sms-modal');
}
export function showPhoneNumberSendSms() {
    showModal('.phone-number--send-sms-modal');
}
export function hideWithdrawMSGModal() {
    hideModal('.withdraw-msg-modal');
}

export function showWithdrawMSGModal() {
    showModal('.withdraw-msg-modal');
}
export function hideResetPWModal() {
    hideModal('.modal-content-forgot-password');
}
export function showResetPWModal() {
    showModal('.modal-content-forgot-password');
}
export function hideAdjoePWModal() {
    hideModal('.modal-content-download-mobile');
}
export function showAdjoeModal() {
    showModal('.modal-content-download-mobile');
}
export function hideChangePWModal() {
    hideModal('.modal-content-reset-password');
}
export function showChangePWModal() {
    showModal('.modal-content-reset-password');
}
export function hideReferralModal() {
    hideModal('.referral-modal');
}

export function showReferralModal() {
    showModal('.referral-modal');
}
export function hideRewardsModal() {
    hideModal('.rewards-modal');
}
export function showRewardsModal() {
    if (isLoggedIn() == false) {
        showLoginModal()
    } else {
        showModal('.rewards-modal');
    }
}
export function hideRegisterModal() {
    hideModal('.modal-content-register');
}
export function showRegisterModal() {
    showModal('.modal-content-register');
}
export function hideGiftCardModal() {
    hideModal('.giftcard-modal');
}
export function showGiftCardModal() {
    showModal('.giftcard-modal');
}

function getCookie(cookieName) {
    let cookie = {};
    document.cookie.split(";").forEach(function (el) {
        let [key, value] = el.split("=");
        cookie[key.trim()] = value;
    });
    return cookie[cookieName];
}
export function hasSomeParentTheClass(element, classname) {
    if (element.className == undefined) return false
    if (element.className.split(' ').indexOf(classname) >= 0) return true;
    return element.parentNode && hasSomeParentTheClass(element.parentNode, classname);
}
export function isLoggedIn() {
    if (typeof document == 'undefined') {
        return setTimeout(() => { isLoggedIn() }, 1000);
    } else {
        return getCookie('session_token') != undefined ? true : false;

    }
}

export const EUC = [
    "AT",  // Austria
    "BE",  // Belgium
    "BG",  // Bulgaria
    "HR",  // Croatia
    "CY",  // Cyprus
    "CZ",  // Czech Republic
    "DK",  // Denmark
    "EE",  // Estonia
    "FI",  // Finland
    "FR",  // France
    "DE",  // Germany
    "GR",  // Greece
    "HU",  // Hungary
    "IE",  // Ireland
    "IT",  // Italy
    "LV",  // Latvia
    "LT",  // Lithuania
    "LU",  // Luxembourg
    "MT",  // Malta
    "NL",  // Netherlands
    "PL",  // Poland
    "PT",  // Portugal
    "RO",  // Romania
    "SK",  // Slovakia
    "SI",  // Slovenia
    "ES",  // Spain
    "SE",  // Sweden
]





export async function postAxios(link, data, wall_id) {
    const fingers = await getFingerprints();
    const info = await axios.post(`https://util.paidcash.co${link}`, {
        ...data,
        fingers,
    },
        {
            headers: {
                wall_id: wall_id
            }
        })
    return info
}

export function getMobileOperatingSystem(userAgent) {

    // Windows Phone must come first because its UA also contains "Android"
    let s = ['*', 'web',];
    if (/windows phone/i.test(userAgent)) {
        s.push("windows phone");
    }

    if (/android/i.test(userAgent)) {
        s.push("android");
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        s.push("ios");
    }
    if (/iPad/.test(userAgent)) {
        s.push("ipad");
    }
    if (/iPhone/.test(userAgent)) {
        s.push("iphone");
    }
    if (/iPod/.test(userAgent)) {
        s.push("ipod");
    }

    if (/windows|Windows/.test(userAgent)) {
        s.push("windows");
        s.push("desktop");
    }
    if (/Macintosh|macintosh/.test(userAgent)) {
        s.push("macos");
        s.push("desktop");

    }
    if (/linux|Linux/.test(userAgent)) {
        s.push("windows");
        s.push("linux");
        s.push("desktop");

    }
    return s;
}